<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceData-container common-padding">
    <!-- <div class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">在职人数</div>
        <div class="item-num">{{ countNum.allNum }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">打卡总数</div>
        <div class="item-num">{{ countNum.total }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">未打卡数</div>
        <div class="item-num">{{ countNum.noNum }}</div>
      </div>
    </div> -->
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          filterable
          @change="companyFun"
          placeholder="请选择"
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="common-input-container">
        <div class="tableScreen">
          <el-date-picker
            v-show="tableRadio == '按日'"
            v-model="tableDate"
            type="date"
            placeholder="选择日"
            :picker-options="pickerOptions"
            @change="(e) => onchangeDate(e, 'W')"
            :clearable="false"
          >
          </el-date-picker>
          <el-date-picker
            v-show="tableRadio == '按月'"
            v-model="tableDate"
            type="month"
            @change="(e) => onchangeDate(e, 'M')"
            placeholder="选择月"
            :clearable="false"
          >
          </el-date-picker>
          <el-radio-group
            class="date"
            v-model="tableRadio"
            @change="isMonthWeekFun"
          >
            <el-radio-button label="按日"></el-radio-button>
            <el-radio-button label="按月"></el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            searchBtn();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
      <el-button class="common-screen-btn" @click="() => download()"
        >导出数据表格</el-button
      >
    </div>
    <!-- 月份表 -->
    <el-table
      class="common-table"
      :data="tableData"
      v-show="this.params.type == 1"
    >
      <el-table-column
        prop="nickName"
        label="姓名"
        width="160"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="account"
        label="工号"
        width="150"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="workNum"
        label="出勤天数"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="workHour"
        label="工作时长"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="lateNum"
        label="迟到次数"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="lateTime"
        label="迟到时长"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="leaveEarlyNum"
        label="早退次数"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="leaveEarlyTime"
        label="早退时长"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="sbMissNum"
        label="上班缺卡次数"
        width="120"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="xbMissNum"
        label="下班缺卡次数"
        width="120"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="每日打卡状态" align="center">
        <el-table-column
          v-for="(item, mIndex) in arrWeekMonth"
          :key="item.date"
          :label="`${item.week}\n(${item.date})`"
          width="120px"
        >
          <template slot-scope="scope">
            <div>
              <span>{{
                scope.row.recordState &&
                  scope.row.recordState[mIndex] &&
                  scope.row.recordState[mIndex].state || "未打卡"
              }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <!-- 日期表 -->
    <el-table
      class="common-table"
      :data="tableData"
      v-show="this.params.type == 0"
    >
      <el-table-column
        prop="nickName"
        label="姓名"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="departName"
        label="部门"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="account"
        label="工号"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="post"
        label="职位"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="checkTime"
        label="日期"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="arrange"
        label="班次"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="sbTime"
        label="上班打卡时间"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="startState"
        label="上班打卡状态"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="xbTime"
        label="下班打卡时间"
        :formatter="tableColumn"
      ></el-table-column
      ><el-table-column
        prop="endState"
        label="下班打卡状态"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="outTime"
        label="挂起时长"
        :formatter="tableColumn"
      ></el-table-column>
    </el-table>
    <!-- <el-table class="common-table" :data="tableData">
      <el-table-column prop="shopLogo" width="160px">
        <template slot-scope="scope">
          <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shopLogo"
              :src="scope.row.shopLogo"
            />
            <span v-if="!scope.row.shopLogo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="time1"
        label="上班打卡时间"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="time2"
        label="下班打卡时间"
        :formatter="tableColumn"
      ></el-table-column>
    </el-table> -->
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  DateTransform,
  Config,
  tableColumn,
  getWeekMonthInfo,
  getWeek
} from "../../utils/index.js";
import { commonDepartAll } from "../../service/common.js";
import { monthList, dateList } from "../../service/dataInfo.js";
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      DateTransform,
      tableColumn,
      btnLoading: false,
      loading: false,
      BreadcrumbData: [
        {
          title: "首页",
          isLink: true,
          url: "/index"
        },
        {
          title: "数据",
          isLink: false
        },
        {
          title: "考勤统计",
          isLink: false
        }
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        type: 0, // 筛选类型 今天/30天/90天 ...
        pageNum: 1,
        pageSize: 10,
        companyId: 0
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      countNum: {},
      serveData: {}, // 店铺服务数据
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      tableData: [],
      tableDate: new Date(), // 默认月份 new Data()
      arrWeekMonth: getWeekMonthInfo(new Date()),
      arrWeekDate: getWeek(new Date()),
      tableRadio: "按日"
    };
  },
  mounted() {},
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    let type = this.params.type;
    if (type == 0) {
      this.getDateList();
    } else if (type == 1) {
      this.getMonthList();
    }
  },
  methods: {
    download() {
      // 导出项目KPI
      let params = { ...this.params };
      delete params.pageNum;
      delete params.pageSize;
      if(this.tableRadio == '按日'){
        params.startDate = DateTransform(this.tableDate);
        params.endDate = DateTransform(this.tableDate);
      }else{
        let arrWeekMonth = [...this.arrWeekMonth];
        params.startDate = arrWeekMonth[0]["date"];
        params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
      }
      console.log(params,'考勤params');
      let url = Config.api.url + "/cms/cmsRecordDay/queryCurrentExport";
      let paramArr = [];
      for (let item in params) {
        paramArr.push(item + "=" + params[item]);
        // if (params[item]) {
        //   paramArr.push(item + "=" + params[item]);
        // }
      }
      url = url + "?" + paramArr.join("&");
      console.log(url,'url');
      window.location.href = url;
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      let timetype = this.params.type;
      if (timetype == 0) {
        this.getDateList();
      } else if (timetype == 1) {
        this.getMonthList();
      }
    },
    searchBtn() {
      let type = this.params.type;
      if (type == 0) {
        this.getDateList();
      } else if (type == 1) {
        this.getMonthList();
      }
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        type: 0, // 筛选类型 今天/30天/90天 ...
        companyId: this.rolesCompany[0].id
      };
      this.arrWeekMonth = getWeekMonthInfo(new Date());
      this.arrWeekDate = getWeek(new Date());
      this.tableDate = new Date();
      this.tableRadio = "按日";

      let timetype = this.params.type;
      if (timetype == 0) {
        this.getDateList();
      } else if (timetype == 1) {
        this.getMonthList();
      }
    },
    async getMonthList() {
      this.loading = true;
      let params = { ...this.params };
      console.log(params, "收集params---month");
      const { data: res } = await monthList(params);
      this.pagination.total = res.total;
      this.pagination.size = res.size;
      this.tableData = res.records;
      this.loading = false;
      console.log(res, "shuju");
    },
    async getDateList() {
      this.loading = true;
      let params = { ...this.params };
      params.startDate = DateTransform(this.tableDate);
      params.endDate = DateTransform(this.tableDate);
      console.log(params, "收集params---date");
      const { data: res } = await dateList(params);
      this.pagination.total = res.total;
      this.pagination.size = res.size;
      this.tableData = res.records;
      this.loading = false;
      console.log(res, "shuju--date");
    },
    companyFun() {
      let type = this.params.type;
      if (type == 0) {
        this.getDateList();
      } else if (type == 1) {
        this.getMonthList();
      }
    },
    isMonthWeekFun(e) {
      // 按月/按周按钮
      let params = { ...this.params };
      params.pageNum = 1;
      if (e == "按月") {
        let arrWeekMonth = [...this.arrWeekMonth];
        params.startDate = arrWeekMonth[0]["date"];
        params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
        this.params = params;
        params.type = 1;
        this.getMonthList();
      }
      if (e == "按日") {
        let arrWeekDate = [...this.arrWeekDate];
        params.startDate = arrWeekDate[0]["date"];
        params.endDate = arrWeekDate[arrWeekDate.length - 1]["date"];
        this.params = params;
        params.type = 0;
        this.getDateList();
      }
    },
    // 改变日期
    onchangeDate(e, type) {
      // type  -- M月 -- W日
      this.params.pageNum = 1
      let params = { ...this.params };
      if (type == "M") {
        let arrWeekMonth;
        arrWeekMonth = getWeekMonthInfo(e);
        params.startDate = arrWeekMonth[0]["date"];
        params.endDate = arrWeekMonth[arrWeekMonth.length - 1]["date"];
        this.arrWeekMonth = arrWeekMonth;

        this.params = params;
        params.type = 1;
        this.getMonthList();
      }
      if (type == "W") {
        // let arrWeekDate;
        // arrWeekDate = getWeek(e);
        params.startDate = DateTransform(this.tableDate);
        params.endDate = DateTransform(this.tableDate);
        // this.arrWeekDate = arrWeekDate;
        this.params = params;
        params.type = 0;
        this.getDateList();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.serviceData-container {
  text-align: left;

  .iconImg {
    width: 40px;
  }

  .serviceData-content {
    margin: 48px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    #echartsRadar {
      flex: 1;
      height: 300px;
      display: none;
    }

    .serviceData-table {
      display: table;
      width: 100%;
      border-collapse: collapse;

      td,
      th {
        text-align: center;
        border: 1px solid #c9c9c9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }

      th {
        background: #f5f5f5;
        height: 60px;
        line-height: 60px;
        font-weight: 600;
      }

      td {
        height: 85px;
        line-height: 85px;
      }
    }
  }
  .tableScreen {
    .date {
      margin-left: 16px;
    }
  }
  /deep/ .el-table .cell {
    white-space: pre-line;
  }
}
</style>
